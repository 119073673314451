import Calendar from "./Calendar";
// import Slideshow from "./Slideshow";
import { Carousel } from './Carousel';

function Landing() {
  const carouselData = [
    { src: "/images/home/BeerBike2024.jpg", alt: "Beer Bike Powder 2024" },
    { src: "/images/s2025-banner/TFW.jpg", alt: "Wiess O-Week PAAs 2024" },
    { src: "/images/home/WarPig2024.jpg", alt: "War Pig Beer Bike 2024" },
    { src: "/images/s2025-banner/OWeekPAAs2024.jpg", alt: "Wiess O-Week PAAs 2024" },
    { src: "/images/s2025-banner/WiessFinest2024.jpg", alt: "Wiess Finest 2024" },
    { src: "/images/s2025-banner/Beach.jpg", alt: "Wiess Summit 2024" },
    { src: "/images/s2025-banner/PumpkinCaroling.jpg", alt: "My GOAT Luiz 2024" },
    { src: "/images/s2025-banner/PaintingUpdated.jpg", alt: "TFFW Painting 2024" },

    { src: "/images/home/CollegeNight2024.jpg", alt: "College Night 2024" },
    { src: "/images/home/PigFloat2024.jpg", alt: "Pig Float 2024" },
    { src: "/images/home/Volleyball2024.jpg", alt: "Volleyball 2024" },
    // Add more items as needed
  ];

  const banner = () => {
    return (
      <button
        style={{ zIndex: 100}}
        name="Committees"
        id="committees-banner"
        className="alert-banner"
      >
      <a href="newstudents" style={{ fontFamily: 'EB Garamond, serif', color: 'white' }}>
         Beer Bike is here!
      </a>
      </button>
    );
  };

  const welcome = () => {
    return (
      <div
        data-w-id="d25a4d8a-92c3-be77-917b-106bdb906dcf"
        className="welcome-msg-wrap"
        style={{ overflow: "auto" }}
      >
        <div style={{ maxWidth: "100%", whiteSpace: "normal" }}>
          <h2 className="d1">Welcome to</h2>
          <h1 className="d2" style={{ maxWidth: "100%", whiteSpace: "normal" }}>
            Wiess College
          </h1>
        </div>
        {/* <img
          style={{ marginTop: "30px" }}
          height="30"
          src="images/general/saagardhanjani.png"
          alt="saagar dhanjani"
        /> */}
      </div>
    );
  };

  return (
    <div className="content">
      {banner()}

      <div className="landing-wrap">
        <div className="w-container">
          <div className="welcome-hero-wrap">
            {welcome()}
          </div>
          <Carousel data={carouselData} />
          <Calendar />
        </div>
      </div>
    </div>
  );
}

export default Landing;
